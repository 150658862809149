.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.downloadImageContainer {
  display: flex;
  justify-content: center;
  margin-right: 40px;
}

.downloadContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
}

.downloadContentTitle {
  font-family: "Galada";
  font-size: 21px;
  color: white;
  margin-bottom: 10px;
}

.playStoreDownloadButton {
  display: flex;
  margin-right: 4px;
  cursor: pointer;
}

.appStoreDownloadButton {
  margin-left: 4px;
  cursor: pointer;
}

@media (max-width: 990px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .downloadImageContainer {
    margin-right: 0px;
  }

  .downloadContentContainer {
    margin-left: 0px;
    margin-top: 40px;
  }
}
