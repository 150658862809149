@import url("./AppFonts.css");

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--purple);
  overflow-x: hidden;
}

.Loading-Indicator {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.No-Internet-Indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: red;
}

.No-Internet-Indicator-Text {
  text-align: center;
  color: white;
  font-size: 15px;
}
