.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 100px;
  padding-left: 120px;
  padding-right: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.topNavigatioBar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}

.innerContainer {
  max-width: 900px;
}

.nikosMemory {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.text {
  flex: 1;
  font-family: Lato;
  font-size: 20px;
  line-height: 30px;
  margin-top: 24px;
  color: white;
}

li {
  margin-bottom: 20px;
}

.contentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 92px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 30%;
}

.teamTitle {
  margin-top: 60px;
  text-align: center;
  font-family: "Lato";
  font-weight: bold;
  font-size: 21px;
  color: white;
}

.memberInfoRowContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.memberInfoContainer {
  display: flex;
  flex-direction: column;
  margin-left: 72px;
}

.memberName {
  font-family: "Lato";
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.memberDescription {
  margin-top: 20px;
  font-family: "Lato";
  font-size: 20px;
  color: white;
}

.categoryTitle {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 700;
}

.categoryItemText {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 400;
}

.youtube {
  margin-top: 40px;
}

@media (max-width: 990px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 180px;
    overflow-y: scroll;
  }

  .contentRow {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 40px;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
  }

  .text {
    font-size: 18px;
    margin-top: 20px;
  }

  .categoryTitle {
    font-size: 18px;
  }

  .categoryItemText {
    font-size: 18px;
  }

  .memberInfoRowContainer {
    flex-direction: column;
    align-items: center;
  }

  .memberInfoContainer {
    margin-left: 0;
  }

  .teamTitle {
    font-size: 20px;
  }

  .memberName {
    font-family: "Lato";
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }

  .topNavigatioBar {
    position: relative;
  }
}
