.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcomeMessage {
  text-align: center;
  margin-top: 20px;
  font-family: "Lato";
  font-weight: bold;
  font-size: 24px;
  color: white;
}

@media (max-width: 990px) {
  .welcomeMessage {
    font-size: 20px;
  }
}
