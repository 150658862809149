.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 30px 0px;
  background-color: rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
}

.socialTitle {
  font-family: Lato;
  font-size: 22px;
  color: white;
}

.link {
  font-family: Lato;
  font-size: 22px;
  color: white;
  cursor: pointer;
}
.linkMargin {
  margin-top: 10px;
}

@media (max-width: 990px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column {
    align-items: center;
    margin-left: 0px;
    margin-top: 40px;
  }
}
