.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  overflow-y: scroll;
}

.contactInfoContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactInfoTitle {
  font-family: "Lato";
  font-size: 22px;
  font-weight: 600;
  color: white;
}

.contactInfoCategory {
  display: flex;
  font-family: "Lato";
  font-size: 20px;
  margin-top: 10px;
  color: white;
}

.contactInfoContent {
  margin-left: 8px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
