.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--pink);
}

.containerDisabled {
  opacity: 0.5;
}

.label {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 300;
  color: var(--white);
}
