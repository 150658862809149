.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  height: 50px;
  width: 200px;
  border-width: 0px;
  border-radius: 8px;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
}
.input:focus {
  outline: none;
}
.input::placeholder {
  font-family: "Lato";
  font-weight: 400;
}

.error {
  margin-top: 8px;
  font-family: "Lato";
  font-weight: 400;
  color: var(--red);
}
