.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: var(--pink25);
}

.title {
  margin-top: 40px;
  font-family: "Lato";
  font-size: 22px;
  font-weight: 500;
}

.body {
  margin-top: 40px;
  font-family: "Lato";
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
}
