.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  overflow: scroll;
}

.title {
  margin-top: 40px;
  font-family: "Lato";
  font-size: 22px;
  font-weight: 500;
  color: var(--white);
}

.actionMessage {
  flex: 1;
  text-align: center;
  font-family: "Lato";
  font-size: 18px;
  margin-top: 16px;
}

.success {
  color: green;
}

.error {
  color: red;
}
