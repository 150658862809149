.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  overflow-y: scroll;
}

.title {
  width: 100%;
  margin-top: 40px;
  font-family: "Lato";
  font-size: 22px;
  font-weight: 500;
  color: white;
}

.body {
  margin-top: 20px;
  font-family: "Lato";
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  color: white;
}

@media (max-width: 500px) {
  .container {
    padding: 20px;
  }

  .title {
    font-size: 18px;
  }

  .body {
    font-size: 15px;
  }
}
