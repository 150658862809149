@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Galada";
  src: url("./assets/fonts/Galada/Galada-Regular.ttf") format("truetype");
  font-weight: 400;
}