.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.socialIconContainer {
  cursor: pointer;
}

.socialIconContainerMarginLeft {
  margin-left: 20px;
}
