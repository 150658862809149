.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  margin-top: 20px;
}

.bottomButton {
  margin-top: 20px;
}
