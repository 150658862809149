.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.checkbox {
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--black);
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;
}

.checkboxChecked {
  opacity: 1;
}

.label {
  flex: 1;
  margin-left: 8px;
  font-family: "Lato";
  font-size: 18px;
}

.labelComponentContainer {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
}
