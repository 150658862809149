.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 40px;
  overflow-y: auto;
}

.content {
  padding-right: 40px;
  padding-left: 40px;
}

.message {
  text-align: center;
  margin-top: 20px;
  font-family: "Lato";
  font-weight: bold;
  font-size: 24px;
  color: var(--white);
}

.downloadContent {
  margin-top: 40px;
}
